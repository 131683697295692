import { PoolViews } from '@store/permissionlessCreatePoolStore'
import CardViewActive from 'public/icons/grid-white.svg'
import CardView from 'public/icons/grid.svg'
import TableViewActive from 'public/icons/hamburger-white.svg'
import TableView from 'public/icons/hamburger.svg'

export const initilaFilterData: Record<
  string,
  { label: string; count: number }
> = {
  ALL: { label: 'ALL', count: 0 },
  'MY POOLS': { label: 'MY POOLS', count: 0 },
  'RP POOLS': { label: 'RP POOLS', count: 0 },
  LABS: { label: 'LABS', count: 0 },
  SHARED: { label: 'SHARED', count: 1 },
  L1L2: { label: 'L1 / L2', count: 0 },
  MEMES: { label: 'MEMES', count: 0 },
  AMM: { label: 'AMM', count: 0 },
  DEFI: { label: 'DEFI', count: 0 },
}

export const particlesBoost: Record<string, Record<string, any>> = {
  'BTC/USD [wETH-USDC.e]': { boost: 10, zk: '576,725K $ZK per Epoch' },
  'BTC/USD [wstETH-deUSD]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'ETH/USD [wETH-USDC.e]': { boost: 10, zk: '576,725K $ZK per Epoch' },
  'ETH/USD [wETH-deUSD]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'wstETH/USD [wstETH-deUSD]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'SOL/USD [wETH-USDC.e]': { boost: 10, zk: '576,725K $ZK per Epoch' },
  'SOL/USD [wstETH-deUSD]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'ETH/BTC [wETH-USDC.e]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'ETH/USD [wstETH-deUSD]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'ZK/USD [ZK-USDC.e]': { boost: 5, zk: '100K $ZK per Epoch' },
  'SWAP-ONLY [USDC.e-deUSD]': { boost: 5, zk: '576,725K $ZK per Epoch' },
  'SWAP-ONLY [wstETH-wETH]': { boost: 5, zk: '576,725K $ZK per Epoch' },
}

export const PoolViewOptions = [
  {
    value: PoolViews.Card,
    defaultIcon: CardView,
    activeIcon: CardViewActive,
  },
  {
    value: PoolViews.Table,
    defaultIcon: TableView,
    activeIcon: TableViewActive,
  },
]
