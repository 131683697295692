import ExternalLink from 'components/ExternalLink/ExternalLink'
import { getExplorerUrl } from 'config/chains'
import cx from 'classnames'
import { useChainId } from 'rfx/lib/chains'
import { ImSpinner2 } from 'react-icons/im'
import { ReactNode } from 'react'

export type TransactionStatusType = 'muted' | 'loading' | 'success' | 'error'

type Props = {
  status: TransactionStatusType
  text: ReactNode
  txnHash?: string
}

export function TransactionStatus({ status, text, txnHash }: Props) {
  const { chainId } = useChainId()

  const txnLink = `${getExplorerUrl(chainId)}tx/${txnHash}`

  return (
    <div>
      <div
        className={cx('TransactionStatus-title', {
          muted: status === 'muted',
        })}
      >
        {text}
      </div>
      <div className="TransactionStatus-status mt-4">
        {status === 'loading' && (
          <ImSpinner2
            width={60}
            height={60}
            className="spin TransactionStatus-spin"
          />
        )}
        {status !== 'loading' && txnHash && (
          <ExternalLink href={txnLink} className="text-th-active">
            VIEW
          </ExternalLink>
        )}
      </div>
    </div>
  )
}
