import { DEFAULT_CHAIN_ID } from 'config/chains'
import { getTokens } from 'config/tokens'
import { useMemo } from 'react'
import { useChainId } from 'wagmi'

const useActiveChainTokens = () => {
  const chainId = useChainId()
  return useMemo(() => getTokens(DEFAULT_CHAIN_ID), [chainId])
}

export default useActiveChainTokens
