import {
  EXECUTION_FEE_CONFIG_V2,
  GAS_PRICE_ADJUSTMENT_MAP,
  MAX_PRIORITY_FEE_PER_GAS_MAP,
} from 'config/chains'
import { IS_VERBOSE } from 'config/development'
import { BASIS_POINTS_DIVISOR } from 'config/factors'
import { useSettings } from 'context/SettingsContext/SettingsContextProvider'
import { BigNumber } from 'ethers'
import { getProvider } from 'rfx/lib/rpc'
import useSWR from 'swr'

export function useGasPrice(chainId: number) {
  const settings = useSettings()

  const executionFeeConfig = EXECUTION_FEE_CONFIG_V2[chainId]

  const { data: gasPrice } = useSWR<BigNumber | undefined>(
    [
      'gasPrice',
      chainId,
      executionFeeConfig.shouldUseMaxPriorityFeePerGas,
      settings.executionFeeBufferBps,
    ],
    {
      fetcher: () => {
        return new Promise<BigNumber | undefined>(async (resolve, reject) => {
          const provider = getProvider(undefined, chainId)

          if (!provider) {
            resolve(undefined)
            return
          }

          try {
            const feeData = await provider.getFeeData()
            let gasPrice = feeData.gasPrice ?? BigNumber.from(0)

            if (executionFeeConfig.shouldUseMaxPriorityFeePerGas) {
              const maxPriorityFeePerGas = feeData?.maxPriorityFeePerGas?.gt(
                MAX_PRIORITY_FEE_PER_GAS_MAP[chainId] || BigNumber.from(0),
              )
                ? feeData?.maxPriorityFeePerGas ?? BigNumber.from(0)
                : MAX_PRIORITY_FEE_PER_GAS_MAP[chainId] ?? BigNumber.from(0)

              gasPrice = gasPrice.add(maxPriorityFeePerGas || BigNumber.from(0))
            }

            if (settings.executionFeeBufferBps) {
              const buffer = gasPrice
                .mul(settings.executionFeeBufferBps)
                .div(BASIS_POINTS_DIVISOR)
              gasPrice = gasPrice.add(buffer)
            }

            const premium =
              BigNumber.from(GAS_PRICE_ADJUSTMENT_MAP[chainId]) ||
              BigNumber.from('0')

            resolve(gasPrice.add(premium))
          } catch (e) {
            // eslint-disable-next-line no-console
            IS_VERBOSE && console.error(e)
            reject(e)
          }
        })
      },
    },
  )

  return {
    gasPrice: gasPrice === undefined ? undefined : BigNumber.from(gasPrice),
  }
}
