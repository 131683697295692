import { expandDecimals } from '@components/trade/rfx/tradingview/lib/numbers'
import SyntheticsReader from 'abis/SyntheticsReader.json'
import TokenAbi from 'abis/Token.json'
import { getExplorerUrl } from 'config/chains'
import { getContract } from 'config/contracts'
import {
  MAX_PNL_FACTOR_FOR_DEPOSITS_KEY,
  MAX_PNL_FACTOR_FOR_WITHDRAWALS_KEY,
} from 'config/dataStore'
import { getTokenBySymbol } from 'config/tokens'
import { TokensData, useTokensData } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { USD_DECIMALS } from 'rfx/lib/legacy'
import { useMulticall } from 'rfx/lib/multicall'
import { getByKey } from 'rfx/lib/objects'
import useWallet from 'rfx/lib/wallets/useWallet'
import { useMarkets } from './useMarkets'
import { getContractMarketPrices } from './useMarketsInfo'
import { useChainId } from 'rfx/lib/chains'
import { useSyntheticsEvents } from 'context/SyntheticsEvents'

type MarketTokensDataResult = {
  marketTokensData?: TokensData
}

export function useMarketTokensData(p: {
  isDeposit: boolean
}): MarketTokensDataResult {
  const { chainId } = useChainId()
  const { isDeposit } = p
  const { account } = useWallet()
  const { tokensData } = useTokensData()
  const { marketsData, marketsAddresses } = useMarkets()

  const isDataLoaded = tokensData && marketsAddresses?.length
  const { depositStatuses } = useSyntheticsEvents()

  const { data } = useMulticall(chainId, 'useMarketTokensData', {
    key: isDataLoaded
      ? [
          account,
          marketsAddresses.join('-'),
          Object.values(depositStatuses)
            .filter((item) => item.executedTxnHash !== undefined)
            ?.length?.toString(),
        ]
      : undefined,

    // Refresh on every prices update
    refreshInterval: null,
    clearUnusedKeys: true,
    keepPreviousData: true,

    request: () =>
      marketsAddresses!.reduce((requests: any, marketAddress) => {
        const market = getByKey(marketsData, marketAddress)!

        const marketPrices = getContractMarketPrices(tokensData!, market)

        if (marketPrices) {
          const marketProps = {
            marketToken: market.marketTokenAddress,
            longToken: market.longTokenAddress,
            shortToken: market.shortTokenAddress,
            indexToken: market.indexTokenAddress,
          }

          const pnlFactorType = isDeposit
            ? MAX_PNL_FACTOR_FOR_DEPOSITS_KEY
            : MAX_PNL_FACTOR_FOR_WITHDRAWALS_KEY

          requests[`${marketAddress}-prices`] = {
            contractAddress: getContract(chainId, 'SyntheticsReader'),
            abi: SyntheticsReader.abi,
            calls: {
              minPrice: {
                methodName: 'getMarketTokenPrice',
                params: [
                  getContract(chainId, 'DataStore'),
                  marketProps,
                  marketPrices.indexTokenPrice,
                  marketPrices.longTokenPrice,
                  marketPrices.shortTokenPrice,
                  pnlFactorType,
                  false,
                ],
              },
              maxPrice: {
                methodName: 'getMarketTokenPrice',
                params: [
                  getContract(chainId, 'DataStore'),
                  marketProps,
                  marketPrices.indexTokenPrice,
                  marketPrices.longTokenPrice,
                  marketPrices.shortTokenPrice,
                  pnlFactorType,
                  true,
                ],
              },
            },
          }
        }

        requests[`${marketAddress}-tokenData`] = {
          contractAddress: marketAddress,
          abi: TokenAbi.abi,
          calls: {
            totalSupply: {
              methodName: 'totalSupply',
              params: [],
            },
            balance: account
              ? {
                  methodName: 'balanceOf',
                  params: [account],
                }
              : undefined,
          },
        }

        return requests
      }, {}),
    parseResponse: (res) =>
      marketsAddresses!.reduce(
        (marketTokensMap: TokensData, marketAddress: string) => {
          const pricesErrors = res.errors[`${marketAddress}-prices`]
          const tokenDataErrors = res.errors[`${marketAddress}-tokenData`]

          const pricesData = res.data[`${marketAddress}-prices`]
          const tokenData = res.data[`${marketAddress}-tokenData`]

          if (pricesErrors || tokenDataErrors || !pricesData || !tokenData) {
            return marketTokensMap
          }

          const tokenConfig = getTokenBySymbol(chainId, 'RP')

          const minPrice = BigNumber.from(pricesData?.minPrice.returnValues[0])
          const maxPrice = BigNumber.from(pricesData?.maxPrice.returnValues[0])

          marketTokensMap[marketAddress] = {
            ...tokenConfig,
            address: marketAddress,
            prices: {
              minPrice: minPrice?.gt(0)
                ? minPrice
                : expandDecimals(1, USD_DECIMALS),
              maxPrice: maxPrice?.gt(0)
                ? maxPrice
                : expandDecimals(1, USD_DECIMALS),
            },
            totalSupply: BigNumber.from(tokenData?.totalSupply.returnValues[0]),
            balance:
              account && tokenData.balance?.returnValues
                ? BigNumber.from(tokenData?.balance?.returnValues[0])
                : undefined,
            explorerUrl: `${getExplorerUrl(chainId)}/token/${marketAddress}`,
          }

          return marketTokensMap
        },
        {} as TokensData,
      ),
  })

  return {
    marketTokensData: data,
  }
}
