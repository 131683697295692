import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { NATIVE_TOKEN_ADDRESS, convertTokenAddress } from 'config/tokens'
import { Subaccount } from 'context/SubaccountContext/SubaccountContext'
import {
  SetPendingFundingFeeSettlement,
  SetPendingOrder,
  SetPendingPosition,
} from 'context/SyntheticsEvents'
import { TokensData, convertToContractPrice } from 'domain/synthetics/tokens'
import { Token } from 'domain/tokens'
import { BigNumber, Signer, ethers } from 'ethers'
import { getGasLimit } from 'rfx/lib/contracts'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { encodeFunctionData } from 'viem'
import { getPositionKey } from '../positions'
import {
  getSubaccountRouterContract,
  getSubaccountRouterParams,
} from '../subaccount/getSubaccountContract'
import { applySlippageToMinOut, applySlippageToPrice } from '../trade'
import { DecreasePositionSwapType, OrderType } from './types'
import { getPendingOrderFromParams, isMarketOrderType } from './utils'
import {
  PriceOverrides,
  simulateExecuteOrderTxn,
} from './simulateExecuteOrderTxn'
import { compact } from 'lodash'

export type DecreaseOrderParams = {
  account: string
  marketAddress: string
  initialCollateralAddress: string
  initialCollateralDeltaAmount: BigNumber
  swapPath: string[]
  receiveTokenAddress: string
  sizeDeltaUsd: BigNumber
  sizeDeltaInTokens: BigNumber
  acceptablePrice: BigNumber
  triggerPrice: BigNumber | undefined
  minOutputUsd: BigNumber
  isLong: boolean
  decreasePositionSwapType: DecreasePositionSwapType
  orderType:
    | OrderType.MarketDecrease
    | OrderType.LimitDecrease
    | OrderType.StopLossDecrease
  executionFee: BigNumber
  allowedSlippage: number
  skipSimulation?: boolean
  referralCode?: string
  indexToken: Token
  tokensData: TokensData
}

export type DecreaseOrderCallbacks = {
  setPendingTxns: (txns: PendingTransaction[]) => void
  setPendingOrder?: SetPendingOrder
  setPendingPosition?: SetPendingPosition
  setPendingFundingFeeSettlement?: SetPendingFundingFeeSettlement
}

export async function createDecreaseOrderTxn(
  chainId: number,
  signer: Signer,
  subaccount: Subaccount,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  params: DecreaseOrderParams | DecreaseOrderParams[],
  callbacks: DecreaseOrderCallbacks,
  keys?: string[],
) {
  const ps = Array.isArray(params) ? params : [params]
  const exchangeRouter = new ethers.Contract(
    getContract(chainId, 'ExchangeRouter'),
    ExchangeRouter.abi,
    signer,
  )

  const router = subaccount
    ? getSubaccountRouterContract(chainId, subaccount.signer)
    : exchangeRouter

  const routerData = subaccount
    ? getSubaccountRouterParams(chainId)
    : {
        abi: ExchangeRouter.abi,
        address: getContract(chainId, 'ExchangeRouter'),
      }

  const orderVaultAddress = getContract(chainId, 'OrderVault')
  const totalWntAmount = ps.reduce(
    (acc, _p) => acc.add(_p.executionFee),
    BigNumber.from(0),
  )
  const account = ps[0].account
  const encodedPayload = createDecreaseEncodedPayload({
    router,
    orderVaultAddress,
    ps,
    subaccount,
    mainAccountAddress: account,
    chainId,
    keys,
  })
  const simulationEncodedPayload = createDecreaseEncodedPayload({
    router: exchangeRouter,
    orderVaultAddress,
    ps,
    subaccount: null,
    mainAccountAddress: account,
    chainId,
  })

  await Promise.all(
    ps.map(async (p) => {
      if (subaccount && callbacks.setPendingOrder) {
        callbacks.setPendingOrder(
          getPendingOrderFromParams(chainId, 'create', p),
        )
      }
      if (!p.skipSimulation) {
        const primaryPriceOverrides: PriceOverrides = {}
        if (p.triggerPrice != undefined) {
          primaryPriceOverrides[p.indexToken.address] = {
            minPrice: p.triggerPrice,
            maxPrice: p.triggerPrice,
          }
        }
        await simulateExecuteOrderTxn(chainId, {
          account,
          primaryPriceOverrides,
          secondaryPriceOverrides: primaryPriceOverrides,
          createOrderMulticallPayload: simulationEncodedPayload,
          value: totalWntAmount,
          tokensData: p.tokensData,
          errorTitle: `Order error.`,
        })
      }
    }),
  )

  const txnCreatedAt = Date.now()

  if (!signer.provider) {
    throw new Error('No provider found')
  }
  const txnCreatedAtBlock = await signer.provider.getBlockNumber()

  const callData = encodeFunctionData({
    abi: routerData.abi,
    args: [encodedPayload],
    functionName: 'multicall',
  })

  const gasLimit = await getGasLimit(
    router,
    'multicall',
    [encodedPayload],
    totalWntAmount,
  )

  const call = {
    address: routerData.address,
    calldata: callData,
    gas: BigInt(gasLimit.toString()),
    value: BigInt(totalWntAmount.toString()),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  await sendPaymasterTransaction({
    call,
    account,
    setPendingTxns: callbacks.setPendingTxns,
    messageOpts,
    subaccount,
    router: router,
    payload: [encodedPayload],
    method: 'multicall',
  })
  ps.forEach((p) => {
    if (isMarketOrderType(p.orderType)) {
      if (callbacks.setPendingPosition) {
        callbacks.setPendingPosition(
          getPendingPositionFromParams(txnCreatedAt, txnCreatedAtBlock, p),
        )
      }
    }

    if (!subaccount && callbacks.setPendingOrder) {
      callbacks.setPendingOrder(getPendingOrderFromParams(chainId, 'create', p))
    }
  })

  if (callbacks.setPendingFundingFeeSettlement) {
    callbacks.setPendingFundingFeeSettlement({
      orders: ps.map((p) => getPendingOrderFromParams(chainId, 'create', p)),
      positions: ps.map((p) =>
        getPendingPositionFromParams(txnCreatedAt, txnCreatedAtBlock, p),
      ),
    })
  }
}

function getPendingPositionFromParams(
  txnCreatedAt: number,
  txnCreatedAtBlock: number,
  p: DecreaseOrderParams,
) {
  const positionKey = getPositionKey(
    p.account,
    p.marketAddress,
    p.initialCollateralAddress,
    p.isLong,
  )
  return {
    isIncrease: false,
    positionKey,
    collateralDeltaAmount: p.initialCollateralDeltaAmount,
    sizeDeltaUsd: p.sizeDeltaUsd,
    sizeDeltaInTokens: p.sizeDeltaInTokens,
    updatedAt: txnCreatedAt,
    updatedAtBlock: BigNumber.from(txnCreatedAtBlock),
  }
}

export function createDecreaseEncodedPayload({
  router,
  orderVaultAddress,
  ps,
  subaccount,
  mainAccountAddress,
  chainId,
  keys,
}: {
  router: ethers.Contract
  orderVaultAddress: string
  ps: DecreaseOrderParams[]
  subaccount: Subaccount
  mainAccountAddress: string
  chainId: number
  keys?: string[]
}) {
  const multicall = [
    ...ps.flatMap((p) => {
      const isNativeReceive = p.receiveTokenAddress === NATIVE_TOKEN_ADDRESS

      const initialCollateralTokenAddress = convertTokenAddress(
        chainId,
        p.initialCollateralAddress,
        'wrapped',
      )

      const shouldApplySlippage = isMarketOrderType(p.orderType)

      const acceptablePrice = shouldApplySlippage
        ? applySlippageToPrice(
            p.allowedSlippage,
            p.acceptablePrice,
            false,
            p.isLong,
          )
        : p.acceptablePrice

      const minOutputAmount = shouldApplySlippage
        ? applySlippageToMinOut(p.allowedSlippage, p.minOutputUsd)
        : p.minOutputUsd
      const orderParams = {
        addresses: {
          cancellationReceiver: ethers.constants.AddressZero,
          receiver: p.account,
          initialCollateralToken: initialCollateralTokenAddress,
          callbackContract: ethers.constants.AddressZero,
          market: p.marketAddress,
          swapPath: p.swapPath,
          uiFeeReceiver: ethers.constants.AddressZero,
        },
        numbers: {
          sizeDeltaUsd: p.sizeDeltaUsd,
          initialCollateralDeltaAmount: p.initialCollateralDeltaAmount,
          triggerPrice: convertToContractPrice(
            p.triggerPrice ?? BigNumber.from(0),
            p.indexToken.decimals,
          ),
          acceptablePrice: convertToContractPrice(
            acceptablePrice,
            p.indexToken.decimals,
          ),
          executionFee: p.executionFee,
          callbackGasLimit: 0n,
          minOutputAmount,
        },
        orderType: p.orderType,
        decreasePositionSwapType: p.decreasePositionSwapType,
        isLong: p.isLong,
        shouldUnwrapNativeToken: isNativeReceive,
        autoCancel: false,
        referralCode: p.referralCode || ethers.constants.HashZero,
      }

      const cancelOrders =
        keys?.map((item) => {
          return {
            method: 'cancelOrder',
            params: [item],
          }
        }) || []

      return [
        { method: 'sendWnt', params: [orderVaultAddress, p.executionFee] },
        {
          method: 'createOrder',
          params: subaccount
            ? [mainAccountAddress, orderParams]
            : [orderParams],
        },
        ...cancelOrders,
      ]
    }),
  ]

  return compact(multicall)
    .filter(Boolean)
    .map((call) =>
      router.interface.encodeFunctionData(call!.method, call!.params),
    )
}
