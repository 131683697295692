import { create } from 'zustand'
import DataStore from 'abis/DataStore.json'
import SyntheticsReader from 'abis/SyntheticsReader.json'
import { accountPositionListKey, hashedPositionKey } from 'config/dataStore'
import {
  ContractMarketPrices,
  MarketsData,
  getByKey,
  getContractMarketPrices,
} from 'domain/synthetics/markets'
import {
  Position,
  PositionsData,
  PositionsInfoData,
  getEntryPrice,
  getLeverage,
  getLiquidationPrice,
  getPositionKey,
  getPositionNetValue,
  getPositionPendingFeesUsd,
  getPositionPnlUsd,
  parsePositionKey,
  usePositionsConstants,
} from 'domain/synthetics/positions'
import {
  TokensData,
  convertToTokenAmount,
  convertToUsd,
} from 'domain/synthetics/tokens'
import { ethers, BigNumber } from 'ethers'
import { useMulticall } from 'rfx/lib/multicall'
import { getContract } from 'config/contracts'
import { useMarketsStore } from './marketsStore'
import { useAccount } from 'wagmi'
import React, { useMemo } from 'react'
import { useLocalStorageSerializeKey } from 'rfx/lib/localStorage'
import { IS_PNL_IN_LEVERAGE_KEY } from 'config/localStorage'
import { getMarkPrice } from 'domain/synthetics/trade'
import { getPositionFee } from 'domain/synthetics/fees'
import { getPriceImpactForPosition } from 'domain/synthetics/fees/utils/priceImpact'
import { getBasisPoints } from 'rfx/lib/numbers'
import { MAX_ALLOWED_LEVERAGE } from 'config/factors'
import {
  useSyntheticsEvents,
  PositionIncreaseEvent,
  PositionDecreaseEvent,
  PendingPositionUpdate,
} from 'context/SyntheticsEvents'
import { DEFAULT_CHAIN_ID } from 'config/chains'

type PositionsStore = {
  isLoading: boolean
  positionsData: PositionsData | undefined
  positionsInfoData: PositionsInfoData | undefined
  allPossiblePositionsKeys: string[] | undefined
  // setters
  setPositionsInfoData: (positionsInfoData?: PositionsInfoData) => void
  setPositionsData: (positionsData?: PositionsData) => void
  setAllPossiblePositionsKeys: (allPossiblePositionsKeys?: string[]) => void
  setIsLoading: (isLoading: boolean) => void
  refetchCount: number
  refetchPositions: () => void
}

export const usePositionsStore = create<PositionsStore>((set) => ({
  isLoading: false,
  positionsData: {},
  positionsInfoData: {},
  allPossiblePositionsKeys: [],
  setIsLoading: (isLoading) => set({ isLoading }),
  setPositionsData: (positionsData) => set({ positionsData }),
  setPositionsInfoData: (positionsInfoData) => set({ positionsInfoData }),
  setAllPossiblePositionsKeys: (allPossiblePositionsKeys) =>
    set({ allPossiblePositionsKeys }),
  refetchCount: 0,
  refetchPositions: () =>
    set((state) => ({ refetchCount: state.refetchCount + 1 })),
}))

export function useFetchPositions_INTERNAL() {
  const chainId = DEFAULT_CHAIN_ID
  const { address: account } = useAccount()
  const { refetchCount } = usePositionsStore()
  const { tokensData, marketsInfoData } = useMarketsStore()
  const { data: existingPositionsKeysSet } = useMulticall(
    chainId,
    'usePositions-keys',
    {
      key: account ? [account] : null,

      // Refresh on every prices update
      refreshInterval: null,
      clearUnusedKeys: true,
      keepPreviousData: true,

      request: () => ({
        dataStore: {
          contractAddress: getContract(chainId, 'DataStore'),
          abi: DataStore.abi,
          calls: {
            keys: {
              methodName: 'getBytes32ValuesAt',
              params: [accountPositionListKey(account!), 0, 1000],
            },
          },
        },
      }),
      parseResponse: (res) => {
        return new Set(res.data.dataStore.keys.returnValues as string[])
      },
    },
  )

  const keysAndPrices = useKeysAndPricesParams({
    marketsInfoData,
    tokensData,
    account,
    existingPositionsKeysSet,
  })

  const { data: positionsData } = useMulticall(chainId, 'usePositionsData', {
    key: keysAndPrices.contractPositionsKeys.length
      ? [...keysAndPrices.contractPositionsKeys, refetchCount]
      : null,

    // Refresh on every prices update
    refreshInterval: null,
    clearUnusedKeys: true,
    keepPreviousData: true,

    request: () => ({
      reader: {
        contractAddress: getContract(chainId, 'SyntheticsReader'),
        abi: SyntheticsReader.abi,
        calls: {
          positions: {
            methodName: 'getAccountPositionInfoList',
            params: [
              getContract(chainId, 'DataStore'),
              getContract(chainId, 'ReferralStorage'),
              keysAndPrices!.contractPositionsKeys,
              keysAndPrices!.marketsPrices,
              // uiFeeReceiver
              ethers.constants.AddressZero,
            ],
          },
        },
      },
    }),
    parseResponse: (res) => {
      const positions = res.data.reader.positions.returnValues

      return positions.reduce(
        (positionsMap: PositionsData, positionInfo: any, i: any) => {
          const { position, fees } = positionInfo
          const { addresses, numbers, flags, data } = position
          const {
            account,
            market: marketAddress,
            collateralToken: collateralTokenAddress,
          } = addresses

          // Empty position
          if (BigNumber.from(numbers.increasedAtBlock).eq(0)) {
            return positionsMap
          }

          const positionKey = getPositionKey(
            account,
            marketAddress,
            collateralTokenAddress,
            flags.isLong,
          )
          const contractPositionKey = keysAndPrices!.contractPositionsKeys[i]

          positionsMap[positionKey] = {
            key: positionKey,
            contractKey: contractPositionKey,
            account,
            marketAddress,
            collateralTokenAddress,
            sizeInUsd: BigNumber.from(numbers.sizeInUsd),
            sizeInTokens: BigNumber.from(numbers.sizeInTokens),
            collateralAmount: BigNumber.from(numbers.collateralAmount),
            increasedAtBlock: BigNumber.from(numbers.increasedAtBlock),
            decreasedAtBlock: BigNumber.from(numbers.decreasedAtBlock),
            isLong: flags.isLong,
            pendingBorrowingFeesUsd: BigNumber.from(
              fees.borrowing.borrowingFeeUsd,
            ),
            fundingFeeAmount: BigNumber.from(fees.funding.fundingFeeAmount),
            claimableLongTokenAmount: BigNumber.from(
              fees.funding.claimableLongTokenAmount,
            ),
            claimableShortTokenAmount: BigNumber.from(
              fees.funding.claimableShortTokenAmount,
            ),
            data,
          }

          return positionsMap
        },
        {} as PositionsData,
      )
    },
  })

  const optimisticPositionsData = useOptimisticPositions({
    positionsData: positionsData,
    allPositionsKeys: keysAndPrices?.allPositionsKeys,
  })

  return optimisticPositionsData
}

export function useInitiatePositionsInfo() {
  const chainId = DEFAULT_CHAIN_ID
  const savedIsPnlInLeverage = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false,
  )

  const setPositionsInfoData = usePositionsStore((s) => s.setPositionsInfoData)
  const setIsLoading = usePositionsStore((s) => s.setIsLoading)

  const showPnlInLeverage = !!savedIsPnlInLeverage

  const marketsInfoData = useMarketsStore((s) => s.marketsInfoData)
  const tokensData = useMarketsStore((s) => s.tokensData)

  const positionsData = useFetchPositions_INTERNAL()

  const { minCollateralUsd } = usePositionsConstants(chainId)

  const data = React.useMemo(() => {
    if (
      !marketsInfoData ||
      !tokensData ||
      !positionsData ||
      !minCollateralUsd
    ) {
      return {
        isLoading: true,
      }
    }

    const positionsInfoData = Object.keys(positionsData).reduce(
      (acc: PositionsInfoData, positionKey: string) => {
        const position = positionsData[positionKey]!

        const marketInfo = getByKey(marketsInfoData, position.marketAddress)

        const indexToken = marketInfo?.indexToken
        const pnlToken = position.isLong
          ? marketInfo?.longToken
          : marketInfo?.shortToken
        const collateralToken = getByKey(
          tokensData,
          position.collateralTokenAddress,
        )

        if (!marketInfo || !indexToken || !pnlToken || !collateralToken) {
          return acc
        }

        const markPrice = getMarkPrice({
          prices: indexToken.prices,
          isLong: position.isLong,
          isIncrease: false,
        })
        const collateralMinPrice = collateralToken.prices.minPrice

        const entryPrice = getEntryPrice({
          sizeInTokens: position.sizeInTokens,
          sizeInUsd: position.sizeInUsd,
          indexToken,
        })

        const pendingFundingFeesUsd = convertToUsd(
          position.fundingFeeAmount,
          collateralToken.decimals,
          collateralToken.prices.minPrice,
        )!

        const pendingClaimableFundingFeesLongUsd = convertToUsd(
          position.claimableLongTokenAmount,
          marketInfo.longToken.decimals,
          marketInfo.longToken.prices.minPrice,
        )!
        const pendingClaimableFundingFeesShortUsd = convertToUsd(
          position.claimableShortTokenAmount,
          marketInfo.shortToken.decimals,
          marketInfo.shortToken.prices.minPrice,
        )!

        const pendingClaimableFundingFeesUsd =
          pendingClaimableFundingFeesLongUsd?.add(
            pendingClaimableFundingFeesShortUsd,
          )

        const totalPendingFeesUsd = getPositionPendingFeesUsd({
          pendingBorrowingFeesUsd: position.pendingBorrowingFeesUsd,
          pendingFundingFeesUsd,
        })

        const closingPriceImpactDeltaUsd = getPriceImpactForPosition(
          marketInfo,
          position.sizeInUsd.mul(-1),
          position.isLong,
          { fallbackToZero: true },
        )

        const positionFeeInfo = getPositionFee(
          marketInfo,
          position.sizeInUsd,
          closingPriceImpactDeltaUsd.gt(0),
          undefined,
        )

        const closingFeeUsd = positionFeeInfo.positionFeeUsd

        const collateralUsd = convertToUsd(
          position.collateralAmount,
          collateralToken.decimals,
          collateralMinPrice,
        )!

        const remainingCollateralUsd = collateralUsd.sub(totalPendingFeesUsd)

        const remainingCollateralAmount = convertToTokenAmount(
          remainingCollateralUsd,
          collateralToken.decimals,
          collateralMinPrice,
        )!

        const pnl = getPositionPnlUsd({
          marketInfo: marketInfo,
          sizeInUsd: position.sizeInUsd,
          sizeInTokens: position.sizeInTokens,
          markPrice,
          isLong: position.isLong,
        })

        const pnlPercentage =
          collateralUsd && !collateralUsd.eq(0)
            ? getBasisPoints(pnl, collateralUsd)
            : BigNumber.from(0)

        const netValue = getPositionNetValue({
          collateralUsd: collateralUsd,
          pnl,
          pendingBorrowingFeesUsd: position.pendingBorrowingFeesUsd,
          pendingFundingFeesUsd: pendingFundingFeesUsd,
          closingFeeUsd,
        })

        const pnlAfterFees = pnl.sub(totalPendingFeesUsd).sub(closingFeeUsd)
        const pnlAfterFeesPercentage = !collateralUsd.eq(0)
          ? getBasisPoints(pnlAfterFees, collateralUsd.add(closingFeeUsd))
          : BigNumber.from(0)

        const leverage = getLeverage({
          sizeInUsd: position.sizeInUsd,
          collateralUsd: collateralUsd,
          pnl: showPnlInLeverage ? pnl : undefined,
          pendingBorrowingFeesUsd: position.pendingBorrowingFeesUsd,
          pendingFundingFeesUsd: pendingFundingFeesUsd,
        })

        const leverageWithPnl = getLeverage({
          sizeInUsd: position.sizeInUsd,
          collateralUsd: collateralUsd,
          pnl,
          pendingBorrowingFeesUsd: position.pendingBorrowingFeesUsd,
          pendingFundingFeesUsd: pendingFundingFeesUsd,
        })

        const hasLowCollateral = leverage?.gt(MAX_ALLOWED_LEVERAGE) || false

        const liquidationPrice = getLiquidationPrice({
          marketInfo,
          collateralToken,
          sizeInUsd: position.sizeInUsd,
          sizeInTokens: position.sizeInTokens,
          collateralUsd,
          collateralAmount: position.collateralAmount,
          userReferralInfo: undefined,
          minCollateralUsd,
          pendingBorrowingFeesUsd: position.pendingBorrowingFeesUsd,
          pendingFundingFeesUsd,
          isLong: position.isLong,
        })

        acc[positionKey] = {
          ...position,
          marketInfo,
          indexToken,
          collateralToken,
          pnlToken,
          markPrice,
          entryPrice,
          liquidationPrice,
          collateralUsd,
          remainingCollateralUsd,
          remainingCollateralAmount,
          hasLowCollateral,
          leverage,
          leverageWithPnl,
          pnl,
          pnlPercentage,
          pnlAfterFees,
          pnlAfterFeesPercentage,
          netValue,
          closingFeeUsd,
          pendingFundingFeesUsd,
          pendingClaimableFundingFeesUsd,
        }

        return acc
      },
      {} as PositionsInfoData,
    )

    return {
      positionsInfoData,
      isLoading: false,
    }
  }, [
    marketsInfoData,
    minCollateralUsd,
    positionsData,
    showPnlInLeverage,
    tokensData,
  ])

  React.useEffect(() => {
    setPositionsInfoData(data.positionsInfoData)
    setIsLoading(data.isLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}

const MAX_PENDING_UPDATE_AGE = 600 * 1000 // 10 minutes

export function useKeysAndPricesParams(p: {
  account: string | null | undefined
  marketsInfoData: MarketsData | undefined
  tokensData: TokensData | undefined
  existingPositionsKeysSet: Set<string> | undefined
}) {
  const { account, marketsInfoData, tokensData, existingPositionsKeysSet } = p

  return useMemo(() => {
    const values = {
      allPositionsKeys: [] as string[],
      contractPositionsKeys: [] as string[],
      marketsPrices: [] as ContractMarketPrices[],
    }

    if (!account || !marketsInfoData || !tokensData) {
      return values
    }

    const markets = Object.values(marketsInfoData)

    for (const market of markets) {
      const marketPrices = getContractMarketPrices(tokensData, market)

      if (!marketPrices) {
        continue
      }

      const collaterals = market.isSameCollaterals
        ? [market.longTokenAddress]
        : [market.longTokenAddress, market.shortTokenAddress]

      for (const collateralAddress of collaterals) {
        for (const isLong of [true, false]) {
          const positionKey = getPositionKey(
            account,
            market.marketTokenAddress,
            collateralAddress,
            isLong,
          )
          values.allPositionsKeys.push(positionKey)

          const contractPositionKey = hashedPositionKey(
            account,
            market.marketTokenAddress,
            collateralAddress,
            isLong,
          )

          if (existingPositionsKeysSet?.has(contractPositionKey)) {
            values.contractPositionsKeys.push(contractPositionKey)
            values.marketsPrices.push(marketPrices)
          }
        }
      }
    }

    return values
  }, [account, existingPositionsKeysSet, marketsInfoData, tokensData])
}

export function useOptimisticPositions(p: {
  positionsData: PositionsData | undefined
  allPositionsKeys: string[] | undefined
}): PositionsData | undefined {
  const { positionsData, allPositionsKeys } = p
  const {
    positionDecreaseEvents,
    positionIncreaseEvents,
    pendingPositionsUpdates,
  } = useSyntheticsEvents()

  return useMemo(() => {
    if (!allPositionsKeys) {
      return undefined
    }

    return allPositionsKeys.reduce((acc, key) => {
      const now = Date.now()

      const lastIncreaseEvent = positionIncreaseEvents
        ? positionIncreaseEvents.filter((e) => e.positionKey === key).pop()
        : undefined
      const lastDecreaseEvent = positionDecreaseEvents
        ? positionDecreaseEvents.filter((e) => e.positionKey === key).pop()
        : undefined

      const pendingUpdate =
        pendingPositionsUpdates?.[key] &&
        (pendingPositionsUpdates[key]?.updatedAt ?? 0) +
          MAX_PENDING_UPDATE_AGE >
          now
          ? pendingPositionsUpdates[key]
          : undefined

      let position: Position

      if (getByKey(positionsData, key)) {
        position = { ...getByKey(positionsData, key)! }
      } else if (pendingUpdate && pendingUpdate.isIncrease) {
        position = getPendingMockPosition(pendingUpdate)
      } else {
        return acc
      }

      if (
        lastIncreaseEvent &&
        lastIncreaseEvent.increasedAtBlock.gt(position.increasedAtBlock) &&
        lastIncreaseEvent.increasedAtBlock.gt(
          lastDecreaseEvent?.decreasedAtBlock || 0,
        )
      ) {
        position = applyEventChanges(position, lastIncreaseEvent)
      } else if (
        lastDecreaseEvent &&
        lastDecreaseEvent.decreasedAtBlock.gt(position.decreasedAtBlock) &&
        lastDecreaseEvent.decreasedAtBlock.gt(
          lastIncreaseEvent?.increasedAtBlock || 0,
        )
      ) {
        position = applyEventChanges(position, lastDecreaseEvent)
      }

      if (
        pendingUpdate &&
        ((pendingUpdate.isIncrease &&
          pendingUpdate.updatedAtBlock.gt(position.increasedAtBlock)) ||
          (!pendingUpdate.isIncrease &&
            pendingUpdate.updatedAtBlock.gt(position.decreasedAtBlock)))
      ) {
        position.pendingUpdate = pendingUpdate
      }

      if (position.sizeInUsd.gt(0)) {
        acc[key] = position
      }

      return acc
    }, {} as PositionsData)
  }, [
    allPositionsKeys,
    pendingPositionsUpdates,
    positionDecreaseEvents,
    positionIncreaseEvents,
    positionsData,
  ])
}

function applyEventChanges(
  position: Position,
  event: PositionIncreaseEvent | PositionDecreaseEvent,
) {
  const nextPosition = { ...position }

  nextPosition.sizeInUsd = event.sizeInUsd
  nextPosition.sizeInTokens = event.sizeInTokens
  nextPosition.collateralAmount = event.collateralAmount
  nextPosition.pendingBorrowingFeesUsd = BigNumber.from(0)
  nextPosition.fundingFeeAmount = BigNumber.from(0)
  nextPosition.claimableLongTokenAmount = BigNumber.from(0)
  nextPosition.claimableShortTokenAmount = BigNumber.from(0)
  nextPosition.pendingUpdate = undefined
  nextPosition.isOpening = false

  if ((event as PositionIncreaseEvent).increasedAtBlock) {
    nextPosition.increasedAtBlock = (
      event as PositionIncreaseEvent
    ).increasedAtBlock
  }

  if ((event as PositionDecreaseEvent).decreasedAtBlock) {
    nextPosition.decreasedAtBlock = (
      event as PositionDecreaseEvent
    ).decreasedAtBlock
  }

  return nextPosition
}

export function getPendingMockPosition(
  pendingUpdate: PendingPositionUpdate,
): Position {
  const { account, marketAddress, collateralAddress, isLong } =
    parsePositionKey(pendingUpdate.positionKey)

  return {
    key: pendingUpdate.positionKey,
    contractKey: hashedPositionKey(
      account,
      marketAddress,
      collateralAddress,
      isLong,
    ),
    account,
    marketAddress,
    collateralTokenAddress: collateralAddress,
    isLong,
    sizeInUsd: pendingUpdate.sizeDeltaUsd ?? BigNumber.from(0),
    collateralAmount: pendingUpdate.collateralDeltaAmount ?? BigNumber.from(0),
    sizeInTokens: pendingUpdate.sizeDeltaInTokens ?? BigNumber.from(0),
    increasedAtBlock: pendingUpdate.updatedAtBlock,
    decreasedAtBlock: BigNumber.from(0),
    pendingBorrowingFeesUsd: BigNumber.from(0),
    fundingFeeAmount: BigNumber.from(0),
    claimableLongTokenAmount: BigNumber.from(0),
    claimableShortTokenAmount: BigNumber.from(0),
    data: '0x',

    isOpening: true,
    pendingUpdate: pendingUpdate,
  }
}
