import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { convertTokenAddress } from 'config/tokens'
import { SetPendingWithdrawal } from 'context/SyntheticsEvents'
import { BigNumber, Signer, ethers } from 'ethers'
import { getGasLimit } from 'rfx/lib/contracts'
import { isAddressZero } from 'rfx/lib/legacy'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { encodeFunctionData } from 'viem'
import { simulateExecuteOrderTxn } from '../orders/simulateExecuteOrderTxn'
import { TokensData } from '../tokens'
import { applySlippageToMinOut } from '../trade'

type Params = {
  account: string
  marketTokenAddress: string
  marketTokenAmount: BigNumber
  initialLongTokenAddress: string
  minLongTokenAmount: BigNumber
  longTokenSwapPath: string[]
  initialShortTokenAddress: string
  shortTokenSwapPath: string[]
  minShortTokenAmount: BigNumber
  executionFee: BigNumber
  allowedSlippage: number
  skipSimulation?: boolean
  tokensData: TokensData
  setPendingTxns: (txns: PendingTransaction[]) => void
  setPendingWithdrawal: SetPendingWithdrawal
  pricesUpdatedAt?: number
}

export enum SwapPricingType {
  TwoStep = 0,
  Shift = 1,
  Atomic = 2,
}
export async function createWithdrawalTxn(
  chainId: number,
  signer: Signer,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  p: Params,
) {
  const contract = new ethers.Contract(
    getContract(chainId, 'ExchangeRouter'),
    ExchangeRouter.abi,
    signer,
  )
  const withdrawalVaultAddress = getContract(chainId, 'WithdrawalVault')

  const isNativeWithdrawal =
    isAddressZero(p.initialLongTokenAddress) ||
    isAddressZero(p.initialShortTokenAddress)

  const executionFee = BigNumber.from(92762500000000)

  const wntAmount = executionFee

  const initialLongTokenAddress = convertTokenAddress(
    chainId,
    p.initialLongTokenAddress,
    'wrapped',
  )
  const initialShortTokenAddress = convertTokenAddress(
    chainId,
    p.initialShortTokenAddress,
    'wrapped',
  )

  const minLongTokenAmount = applySlippageToMinOut(
    p.allowedSlippage,
    p.minLongTokenAmount,
  )
  const minShortTokenAmount = applySlippageToMinOut(
    p.allowedSlippage,
    p.minShortTokenAmount,
  )

  const multicall = [
    { method: 'sendWnt', params: [withdrawalVaultAddress, wntAmount] },
    {
      method: 'sendTokens',
      params: [
        p.marketTokenAddress,
        withdrawalVaultAddress,
        p.marketTokenAmount,
      ],
    },
    {
      method: 'createWithdrawal',
      params: [
        {
          receiver: p.account,
          callbackContract: ethers.constants.AddressZero,
          market: p.marketTokenAddress,
          initialLongToken: initialLongTokenAddress,
          initialShortToken: initialShortTokenAddress,
          longTokenSwapPath: p.longTokenSwapPath,
          shortTokenSwapPath: p.shortTokenSwapPath,
          marketTokenAmount: p.marketTokenAmount,
          minLongTokenAmount,
          minShortTokenAmount,
          shouldUnwrapNativeToken: isNativeWithdrawal,
          executionFee: executionFee,
          callbackGasLimit: BigNumber.from(0),
          uiFeeReceiver: ethers.constants.AddressZero,
        },
      ],
    },
  ]

  const encodedPayload = multicall
    .filter(Boolean)
    .map((call) =>
      contract.interface.encodeFunctionData(call!.method, call!.params),
    )

  if (!p.skipSimulation) {
    await simulateExecuteOrderTxn(chainId, {
      account: p.account,
      primaryPriceOverrides: {},
      secondaryPriceOverrides: {},
      tokensData: p.tokensData,
      createOrderMulticallPayload: encodedPayload,
      method: 'simulateExecuteWithdrawal',
      errorTitle: `Withdrawal error.`,
      value: wntAmount,
      signer,
      extraArgs: [SwapPricingType.TwoStep],
    })
  }

  const calldata = encodeFunctionData({
    abi: ExchangeRouter.abi,
    args: [encodedPayload],
    functionName: 'multicall',
  })

  const gasLimit = await getGasLimit(
    contract,
    'multicall',
    [encodedPayload],
    wntAmount,
  )
  const call = {
    address: getContract(chainId, 'ExchangeRouter'),
    calldata,
    gas: BigInt(gasLimit.toString()),
    hideSentMsg: true,
    hideSuccessMsg: true,
    setPendingTxns: p.setPendingTxns,
    value: BigInt(wntAmount.toString()),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  await sendPaymasterTransaction({
    call,
    account: p.account,
    messageOpts,
    setPendingTxns: p.setPendingTxns,
    router: contract,
    payload: [encodedPayload],
    method: 'multicall',
  })

  p.setPendingWithdrawal({
    account: p.account,
    marketAddress: p.marketTokenAddress,
    marketTokenAmount: p.marketTokenAmount,
    minLongTokenAmount,
    minShortTokenAmount,
    shouldUnwrapNativeToken: isNativeWithdrawal,
  })
}
